import {API_URL} from "../API";
import {decodeWeapons} from "../../builder/WeaponBuilder";

export const getUserProfile = (userId, category) =>
    fetch(`${API_URL}userProfiles/${userId}${category ? `?category=${category}` : ''}`, {credentials: userId === 'leaderboards' ? 'include' : undefined})
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            throw new Error(`Response status was ${response.status}`)
        })
        .then((profile) => enrichLoadouts(profile))


export const enrichLoadouts = (profile) => {
    profile.loadouts = decodeWeapons(profile.loadouts ?? [])
    return profile
}

