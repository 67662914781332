import {Stack, Typography} from "@mui/material";
import MobileAd from "../../components/ads/MobileAd";
import * as React from "react";
import {ContentWeaponImage} from "../UpdatesPage";
import {useIsMobileHook} from "../../utils";

function Buff({title, oldTTK, newTTK, oldSTK, newSTK}) {
    const isMobile = useIsMobileHook()
    title = isMobile ? title.replaceAll('Headshots', 'HS').replaceAll('Headshot', 'HS') : title;
    return <Stack direction='row' alignItems='center' width='100%'>
        <Typography variant='content' minWidth='20%'><b>{title}:</b></Typography>
        <Typography variant='content' minWidth={isMobile ? '45%' : '20%'}>TTK: <s
            style={{color: 'red'}}>{oldTTK}</s> > <a style={{color: 'green'}}>{newTTK}</a> ms</Typography>
        <Typography variant='content' minWidth={isMobile ? '35%' : '20%'}>STK: <s
            style={{color: 'red'}}>{oldSTK}</s> > <b style={{color: 'green'}}>{newSTK}</b></Typography>
    </Stack>
}

export const patchUpdates = () => [
    {
        title: 'Weapon Balancing - Y1S1.2 Update',
        date: new Date(1721214149865),
        // mainImage: PlayerStats1,
        description: <>
            <p>
                We're finally getting our first real weapon balancing update since the release of XDefiant! With the
                Y1S1.2 update we'll see some buffs to the somewhat underused MDR, M4 and MP5 as well as further
                nerfs to
                all 3 snipers. Here I'm going to break down what the new changes will mean in terms of real ingame
                scenarios.
            </p>
            <p style={{marginTop: '30px'}}>
                <i style={{fontSize: '1.2rem', lineHeight: 2}}><b>MDR</b></i><br/>
                <ContentWeaponImage weapon='MDR'/>
                - <i>MDR short-range damage increased from 17 to 19.</i><br/>
                - <i>MDR ADS speed improved from 300ms to 250ms.</i><br/><br/>
                The MDR is definitely the biggest winner in this patch. The increased ADS time speaks for itself,
                the
                MDR becomes on par with the LVOA-C for weapon handling, allowing you to free up some attachment
                slots
                that you might have been using before. The damage increase make a big difference As you can see, the
                MDR is now way more viable, especially against phantoms. The heavy barrel also becomes an
                interesting
                option to reduce the number of headshots required for a lower shot to kill.<br/><br/>
                <b style={{lineHeight: 2}}>Against Normal Enemies (100HP) - 0-20m</b><br/>
                <Buff title='1-2 Headshots' oldTTK={392} newTTK={314} oldSTK={6} newSTK={5}/>
                <Buff title='3+ Headshots' oldTTK={314} newTTK={235} oldSTK={5} newSTK={4}/>
                <i style={{lineHeight: 2}}>With Heavy Barrel</i><br/>
                <Buff title='0-1 Headshots' oldTTK={436} newTTK={349} oldSTK={6} newSTK={5}/>
                <Buff title='3 Headshots' oldTTK={349} newTTK={261} oldSTK={5} newSTK={4}/>
                <br/>
                <b style={{lineHeight: 2}}>Against Phantoms (120HP) - 0-20m</b><br/>
                <Buff title='0 Headshots' oldTTK={549} newTTK={471} oldSTK={8} newSTK={7}/>
                <Buff title='1-2 Headshots' oldTTK={471} newTTK={392} oldSTK={7} newSTK={6}/>
                <Buff title='4 Headshots' oldTTK={392} newTTK={314} oldSTK={6} newSTK={5}/>
                <i style={{lineHeight: 2}}>With Heavy Barrel</i><br/>
                <Buff title='0 Headshots' oldTTK={523} newTTK={436} oldSTK={7} newSTK={6}/>
                <Buff title='3-4 Headshots' oldTTK={436} newTTK={349} oldSTK={6} newSTK={5}/>
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='updates-mdr'/>
            </Stack>
            <p style={{marginTop: '30px'}}>
                <i style={{fontSize: '1.2rem', lineHeight: 2}}><b>M4A1</b></i><br/>
                <ContentWeaponImage weapon='M4A1'/>
                - <i>M4A1 short-range damage increased from 17 to 18.</i><br/><br/>
                Not as game-changing as the MDR buff, but great to see another gun that becomes particularly better
                against phantoms. It's also now possible to get an incredibly low 248ms TTK with the M4 if you hit 4
                headshots!<br/><br/>
                <b style={{lineHeight: 2}}>Against Normal Enemies (100HP) - 0-38m</b><br/>
                <Buff title='2 Headshots' oldTTK={414} newTTK={331} oldSTK={6} newSTK={5}/>
                <Buff title='4 Headshots' oldTTK={331} newTTK={248} oldSTK={5} newSTK={4}/>
                <i style={{lineHeight: 2}}>With Heavy Barrel (not recommended)</i><br/>
                <Buff title='1 Headshot' oldTTK={460} newTTK={368} oldSTK={6} newSTK={5}/>
                <br/>
                <b style={{lineHeight: 2}}>Against Phantoms (120HP) - 0-38m</b><br/>
                <Buff title='0 Headshots' oldTTK={579} newTTK={497} oldSTK={8} newSTK={7}/>
                <Buff title='2 Headshots' oldTTK={414} newTTK={414} oldSTK={7} newSTK={6}/>
                <i style={{lineHeight: 2}}>With Heavy Barrel</i><br/>
                <Buff title='1 Headshot' oldTTK={552} newTTK={460} oldSTK={7} newSTK={6}/>
                <Buff title='4 Headshots' oldTTK={460} newTTK={368} oldSTK={6} newSTK={5}/>
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='updates-m4a1'/>
            </Stack>
            <p style={{marginTop: '30px'}}>
                <i style={{fontSize: '1.2rem', lineHeight: 2}}><b>MP5A2</b></i><br/>
                <ContentWeaponImage weapon='MP5A2'/>
                - <i>MP5A2 medium-range damage increased from 13 to 14.</i><br/>
                - <i>Headshot-damage multiplier increased from 1.35x to 1.4x.</i><br/>
                - <i>Short range increased from 16m to 18m.</i><br/>
                - <i>Medium range increased from 25m to 27m.</i><br/><br/>
                These changes really work on solidifying the MP5 as a longer range SMG option while also increasing
                its best TTK potential by making a 4 headshot kill possible. These changes aren't meta breaking but
                they do give the MP5 a new niche of its own.<br/><br/>
                <b style={{lineHeight: 2}}>Against Normal Enemies (100HP) - 0-18m</b><br/>
                <Buff title='4 Headshots' oldTTK={300} newTTK={225} oldSTK={5} newSTK={4}/>
                <br/>
                <b style={{lineHeight: 2}}>Against Normal Enemies (100HP) - 18-27m</b><br/>
                <Buff title='1 Headshot' oldTTK={525} newTTK={450} oldSTK={8} newSTK={7}/>
                <Buff title='3-4 Headshots' oldTTK={450} newTTK={375} oldSTK={7} newSTK={6}/>
                <Buff title='5 Headshots' oldTTK={375} newTTK={300} oldSTK={6} newSTK={5}/>
                <i style={{lineHeight: 2}}>With Heavy Barrel (not recommended)</i><br/>
                <Buff title='0 Headshot' oldTTK={583} newTTK={500} oldSTK={8} newSTK={7}/>
                <Buff title='2-3 Headshots' oldTTK={500} newTTK={417} oldSTK={7} newSTK={6}/>
                <Buff title='5 Headshots' oldTTK={417} newTTK={333} oldSTK={6} newSTK={5}/>
                <br/>
                <b style={{lineHeight: 2}}>Against Phantoms (120HP) - 18-27m</b><br/>
                <Buff title='0 Headshots' oldTTK={675} newTTK={600} oldSTK={10} newSTK={9}/>
                <Buff title='2-3 Headshots' oldTTK={600} newTTK={525} oldSTK={9} newSTK={8}/>
                <Buff title='4-5 Headshots' oldTTK={525} newTTK={450} oldSTK={8} newSTK={7}/>
                <Buff title='6 Headshots' oldTTK={450} newTTK={375} oldSTK={7} newSTK={6}/>
                <i style={{lineHeight: 2}}>With Heavy Barrel (not recommmended)</i><br/>
                <Buff title='0 Headshots' oldTTK={667} newTTK={583} oldSTK={9} newSTK={8}/>
                <Buff title='3-4 Headshots' oldTTK={583} newTTK={500} oldSTK={8} newSTK={7}/>
                <Buff title='5 Headshots' oldTTK={583} newTTK={417} oldSTK={8} newSTK={6}/>
                <Buff title='6 Headshots' oldTTK={500} newTTK={417} oldSTK={7} newSTK={6}/>
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='updates-mp5'/>
            </Stack>
            <p>
                <i style={{fontSize: '1.2rem', lineHeight: 2}}><b>Sniper Nerfs</b></i><br/>
                <ContentWeaponImage weapon='L115'/>
                - <i>M44 Sprint Out Time (sprint-to-shoot time) increased from 350ms to 400ms.</i><br/>
                - <i>TAC-50 Sprint Out Time increased from 600ms to 625ms.</i><br/>
                - <i>L115 sprint-shoot time increased from 550ms to 575ms.</i><br/>
                - <i>The sniper rifles (M44, TAC-50, and L115) got a small increase in spread when shooting from the
                hip.</i><br/><br/>
                I'm sure these will be very controversial since snipers have already received multiple flinch
                updates. I don't really think this hugely affects the viability of snipers, I think they will remain
                very strong, but it will slow down the most aggressive snipers.
            </p>
        </>
    },
]