import {Accordion, AccordionSummary, Stack, Typography} from "@mui/material";
import {getStat, renderValue, Stats} from "../UserStats";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../../../config/localisation/Localisation";
import CrownIcon from "../../../../assets/icons/crown.png";
import {ArrowDropDown} from "@mui/icons-material";
import AccordionBody from "react-bootstrap/AccordionBody";
import DesktopHorizontalBannerAd from "../../../../components/ads/DesktopHorizontalBannerAd";

const matchStats = ['score', 'kills', 'deaths', 'kdr', 'assists', 'damage']

export const GamemodeIcon = ({gamemode, style}) => {
    try {
        return gamemode ? <img
            src={require(`../../../../assets/game/gamemodes/${gamemode.toLowerCase().replaceAll(' ', '')}.png`)}
            alt={gamemode}
            style={{...style, filter: false ? 'drop-shadow(0px 0px 6px rgba(0,0,0,0.75)' : 'none'}}/> : null
    } catch (e) {
        console.log(e)
        return null
    }
}

export function getPerGameStat(latest, stat) {
    if (stat.gameDiff == null || Stats.find(s => s.label === stat.gameDiff.replaceAll('-', '')) == null) return null
    return getStat(latest, Stats.find(s => s.label === stat.gameDiff.replaceAll('-', '')));
}

function getGameDiff(stat, match, latest) {
    const perGame = getPerGameStat(latest, stat)
    if (perGame == null) return null
    const lowerBetter = stat.gameDiff.startsWith('-')
    return Math.round(((getStat(match, stat) - perGame) * (lowerBetter ? -100 : 100)) / perGame)
}

const GameDiff = ({stat, match, latest}) => {
    const gameDiff = getGameDiff(stat, match, latest)
    return gameDiff == null ? null
        : <Stack direction='row' ml='8px' alignItems='center'>
            <Typography fontSize='0.78rem' color={gameDiff > 0 ? 'green' : 'red'}>{gameDiff}%</Typography>
            <Typography style={{color: 'white', fontSize: '0.5rem', paddingBottom: '2px'}}>{gameDiff > 0 ? '▲' : '▼'}</Typography>
        </Stack>
}

function Statline({match, latest}) {
    const {t} = useTranslation(Namespaces.playerStats)

    return <Stack direction='row' p='0' alignItems='stretch' justifyContent='space-evenly'
               border='1px rgb(60,60,60) solid' backgroundColor='rgb(40,40,40,0.2)'
               borderRadius='5px' width='fill-available'>
            {matchStats.map(ms => Stats.find(s => ms === s.label)).map(s =>
                <Stack m='5px 5px'>
                    <Typography fontSize='0.9rem' fontStyle='normal' color='white'>{t(s.label)}</Typography>
                    <Stack direction='row' alignItems='baseline'>
                        <Typography fontSize='0.8rem' color='white'>{renderValue(s, match)}</Typography>
                        <GameDiff stat={s} match={match} latest={latest}/>
                    </Stack>
                </Stack>
            )}
        </Stack>
}

export const matchResult = (match) => {
    return match.left ? 'Left' : match.victory > 0 ? 'Win' : match.victory < 0 ? 'Loss' : 'Draw';
}

function Match({match, latest}) {
    const {t} = useTranslation(Namespaces.playerStats)

    return <Accordion disableGutters={true} sx={{m: '5px', borderRadius: '10px', borderTopLeftRadius: '10px', backgroundColor: 'rgb(0,0,0,0.2)',
        '&:first-of-type': { borderTopLeftRadius: '10px', borderTopRightRadius: '10px'},
        '&:last-of-type': { borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}}>
        <AccordionSummary sx={{p: '0 15px 0 0', '& .MuiAccordionSummary-content': {m: '6px 0'}}} expandIcon={<ArrowDropDown sx={{color: 'white'}}/>}>
            <Stack p='0px 12px 0px 10px' direction='row' alignItems='center' width='100%'>
            <Stack direction='row' alignItems='center' minWidth='270px'>
                <Stack alignItems='center'>
                    <GamemodeIcon gamemode={match.gamemode} style={{height: '60px', width: '60px', margin: '0 5px'}}/>
                    <Typography mt='3px' variant='small'>{new Date(match.updated).toLocaleTimeString().substring(0,5)}</Typography>
                </Stack>
                <Stack m='0 5px'>
                    {match.matches.ranked.played > 0 ? <Typography lineHeight='1.25' fontSize='0.7rem' fontStyle='oblique'>{t('Ranked')}</Typography> : null}
                    <Typography variant='midHead' fontStyle='normal'>{t(match.gamemode)}</Typography>
                    <Typography variant='mid' fontStyle='oblique'>
                        {matchResult(match)}
                    </Typography>
                </Stack>
            </Stack>
            {match.mvp.count > 0 ? <img src={CrownIcon} style={{marginRight: '25px', height: '30px', width: '30px'}}
                                          alt='mvp-icon'/> : <div style={{minWidth: '60px'}}></div>}
            <Statline match={match} latest={latest}/>
            {/*<IconButton><ArrowDropDown sx={{color: 'white'}}/></IconButton>*/}
            </Stack>
        </AccordionSummary>
        <AccordionBody>
        </AccordionBody>
    </Accordion>
}

const MatchSummary = ({matches, latest}) => {
    const {t} = useTranslation(Namespaces.playerStats)

    return <Stack m='5px'>
        <Typography variant='midHead' m='5px 10px'>{t('matches')}</Typography>
        {matches.reverse().map((m, i) => <>
            <Match match={m} latest={latest}/>
            {(matches.length < 5 && i+1 === matches.length) || ((i+1) % 5 === 0) ? <DesktopHorizontalBannerAd adSlot={`match-summary-${i%3}`}/> : null}
        </>)}
    </Stack>
}

export default MatchSummary