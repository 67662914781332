const translations = {
    pages: {
        weaponBuilder: "Armamento",
        meta: "Meta",
        profiles: "Perfiles",
        more: "Más",
        updates: "Noticias",
        about: "Acerca De",
        leaderboards: "Clasificaciones"
    },
    weapons: {
        weapons: 'Armas',
        selectWeapon: 'Arma',
        'Assault Rifle': 'Fusil de Asalto',
        arShort: 'Asalto',
        SMG: 'Subfusil',
        LMG: 'Ametralladora Ligera',
        lmgShort: 'Ametrall.',
        Shotgun: 'Escopeta',
        sgShort: 'Escop.',
        'Marksman Rifle': 'Fusil de Tiro',
        dmrShort: 'Tiro',
        'Sniper Rifle': 'Fusil de Francotirador',
        sniperShort: 'Francotir.',
        Pistol: 'Pistola',
        ACR: 'ACR',
        M4A1: 'M4A1',
        'AK-47': 'AK-47',
        M16A4: 'M16A4',
        MDR: 'MDR',
        MP5A2: 'MP5A2',
        MP7: 'MP7',
        P90: 'P90',
        'Vector .45': 'Vector .45',
        M249: 'M249',
        'RPK-74': 'RPK-74',
        M60: 'M60',
        M870: 'M870',
        'AA-12': 'AA-12',
        'Double-Barreled': 'Dos Cañones',
        doubleBarreledShort: 'Dos Cañ.',
        'MK 20 SSR': 'MK 20 SSR',
        SVD: 'SVD',
        M44: 'M44',
        'TAC-50': 'TAC-50',
        M1911: 'M1911',
        '686 Magnum': '686 Magnum',
        '93R': '93R',
        M9: 'M9',
        D50: 'D50'
    },
    attachments: {
        attachments: 'Accesorios',
        'Muzzle': 'Boca',
        'Barrel': 'Cañón',
        'Front_Rail': 'Riel Frontal',
        'Optic': 'Óptica',
        'Magazine': 'Cargador',
        'Rear_Grip': 'Empuñadura Trasera',
        'Stock': 'Culata',
        'Lightweight Suppressor': 'Silenciador Ligero',
        'Muzzle Booster': 'Potenciador de Boca',
        'Barrel Extender': 'Extensor de Cañón',
        'Muzzle Break': 'Freno',
        Compensator: 'Compensador',
        Suppressor: 'Silenciador',
        Recon: 'Reconocimiento',
        Stabilizing: 'Estabilizador',
        'Rapid Fire': 'Fuego Rápido',
        Heavy: 'Pesado',
        Stealth: 'Sigilo',
        Lightweight: 'Ligero',
        'Chrome Lined': 'Cromado',
        Choke: 'Estrangulador',
        'Angled Grip': 'Angulada',
        'Superlight Grip': 'Superligera',
        'Vertical Grip': 'Vertical',
        'Tactical Grip': 'Táctica',
        'Small Vertical Grip': 'Vertical Pequeña',
        'PEQ-15 Laser': 'PEQ-15',
        Handstop: 'Freno de Mano',
        'Leather-Wrapped Foregrip': 'Recubierta de Cuero',
        'Pistol Grip': 'Pistola',
        'Reflex Sight': 'Réflex',
        'Holographic Sight': 'Holográfica',
        '3x Scope': 'Mira 3x',
        'ACOG Scope (4x)': 'ACOG (4x)',
        'Variable Zoom Scope (4x-8x)': 'Variable (4x-8x)',
        'CQBSS Scope (8x)': 'CQBSS (8x)',
        'Sniper Scope (12x)': 'Francotirador (12x)',
        'Red Dot Sight': 'Red Dot Sight',
        'Quick Mag': 'Rápido',
        'Lightweight Mag': 'Ligero',
        'Extended Mag': 'Extendido',
        'Fast Mag': 'Rápido+',
        'Grip Tape': 'Cinta de Agarre',
        'Fabric Grip': 'Tela',
        'Leather-Wrapped Grip': 'Recubierta de Cuero',
        'Heavy Grip': 'Pesada',
        'Lightweight Grip': 'Ligera',
        'Quick Draw Grip': 'Desenfundado Rápido',
        'Padded Stock': 'Acolchada',
        'Precision Stock': 'Precisión',
        'Reinforced Stock': 'Reforzada',
        'Lightweight Stock': 'Ligera',
        'Removed Stock': 'Eliminada',
        'Folded Stock': 'Plegable',
        'Collapsed Stock': 'Plegada'
    },
    weaponStats: {
        noWeapon: 'Selecciona una arma para ver estadísticas',
        ttk: 'Tiempo para Matar',
        stk: 'Balas para Matar',
        damageRanges: 'Alcances de Daño',
        short: 'Corto',
        mid: 'Medio',
        long: 'Largo',
        range: 'Alcance',
        'Minimap Visibility Range': 'Alcance Visibilidad en Minimapa',
        'Minimap Visibility Duration': 'Tiempo Visibilidad en Minimapa',
        'Fire Rate': 'Cadencia de Tiro',
        'Damage': 'Daño',
        'Recoil Recovery': 'Recuperación Retroceso',
        'Vertical Recoil': 'Retroceso Vertical',
        'Horizontal Recoil': 'Retroceso Horizontal',
        'Short Range': 'Corto Alcance',
        'Long Range': 'Medio Alcance',
        'Sprint to Fire Time': 'Disparo después de Esprintar',
        'ADS Time': 'Tiempo Apuntar con Mira',
        'Aiming Stability': 'Estabilidad al Apuntado',
        'Move Speed': 'Velocidad Movimiento',
        'ADS Move Speed': 'Velocidad Movimiento al Apuntado',
        'Hipfire Spread': 'Dispersión Desde la Cadera', //Hipfire Spread que seria?
        'adsFlinch': 'Control de Sacudidas',
        'Mag Size': 'Capacidad del Cargador',
        'Reload Time': 'Tiempo Recarga',
        'Extra Mags': 'Cargadores Extras',
        'Bullet Penetration': 'Penetración de Bala',
        //Highlights
        damageRange: 'Alcance',
        recoilControl: 'Control Retroceso',
        adsSpeed: 'Velocidad Apuntar con Mira',
        sprintToFire: 'Disparo después de Esprintar',
        moveSpeed: 'Movimiento',
        adsMoveSpeed: 'Mov. al Apuntado',
        recoilRecovery: 'Recup. Retroceso'
    },
    builder: {
        range: 'Alcance',
        advanced: 'Avanzado',
        compare: 'Comparar Equipamientos',
        loadSavedLoadouts: 'Cargar Equipamiento|\n|Guardado',
        randomWeapon: 'Arma Aleatorio',
        loadoutName: 'Nombre Equipamiento',
        share: 'Compartir',
        resetAttachments: 'Limpiar Accesorios',
        randomAttachments: 'Accesorios Aleatorios',
        statsXdi: 'Estadísticas de armas en colaboración con',
        comparisonFull: 'Ya estás comparando la máxima cantidad de armas.',
        comparisonSelect: 'Seleccionar hasta 5 equipamientos guardados para comparar.',
        stock: 'Estándar',
        savedLoadouts: 'Equipamientos Guardados',
        stockWeapons: 'Armas Estándares',
        multipliers: 'Multiplicadores',
        headshots: 'Tiros de Cabeza',
        chestshots: 'Tiros de Pecho',
        multipliersTooltip: 'Elegir partes del cuerpo para usar en calcular Tiempo/Balas para Matar. Tiros de cabeza llegan primeros, después de pecho y los restantes tiros de torso.',
        accuracy: 'Precisión',
        cleanerDamage: 'Daño incendiario de Cleaner',
        cleanerDamageTooltip: "Incluye 8 daño incendiario para calcular Tiempo/Balas para Matar (no incluye el tiempo que tardan los 'tics')",
        cleanerRange: 'Reducción de alcance de Cleaner',
        cleanerRangeTooltip: 'Incluye 10% reducción de alcance',
        phantomHealth: 'Salud Phantom',
        phantomHealthTooltip: 'Usa 120 puntos de salud para calcular Tiempo/Balas para Matar',
        includeAds: 'Incluir tiempo de apuntar con la mira',
        includeStf: 'Incluir tiempo de disparo después de esprintar',
        includeAdsTooltip: 'Incluye tiempo de apuntar con la mira para calcular Tiempo/Balas para Matar',
        includeStfTooltip: 'Incluye tiempo de disparo después de esprintar para calcular Tiempo/Balas para Matar',
        pelletsMissed: 'Perdigones Errados',
        pelletsMissedTooltip: '(solo se aplica a escopetas)',
        h1Text: 'XDefiant Creador de Armas - Crea tu equipamiento para ver estadísticas detalladas como Tiempo/Balas para Matar y mucho más!',
        h2Text: 'Guarda tus equipamientos y comparar hasta 5 en el gráfico.',
        viewInBuilder: 'Ver en Armamento',
        selectALoadout: 'Seleccionar un equipamiento para ver/editarlo',
        noSavedLoadouts: "No tienes ningún equipamiento guardado",
        tweetLoadout: 'Subir en X',
        saveImage: 'Guardar Imagen'
    },
    homepage: {
        welcome: 'Bienvenido a',
        h1Text: 'La fuente mejor de estadísticas detalladas de armas y los equipamientos metas.<br><br/> Comparar armas y accesorios parar construir tu propio equipamiento o mirar las clases metas de los mejores jugadores de XDefiant.',
        builderTitle: 'Armamento',
        builderDesc: 'Construir y comparar equipamientos y ver estadísticas completas como Tiempo para Matar (TTK), multis de Tiro de Cabeza y aun más!',
        metaTitle: 'Equipamientos Metas',
        metaDesc: 'Mira nuestro tierlist de las mejores armas para modos casual y competitivo. Encuentra los equipamientos metas, creados por los jugadores y creadores top de XDefiant.',
        profileTitle: 'Tu Perfil',
        profileDesc: 'Ver y editar tu perfil',
        h2Text: 'Crea un perfil para ver tus estadísticas de juego, guarda tus equipamientos y tus favoritos de otros usuarios!',
    },
    meta: {
        casual: 'Casual',
        ranked: 'Ranked',
        collaboration: 'en colaboración con',
        rankedTitle: 'XDefiant Ranked Meta Tierlist',
        rankedDesc: "En colaboración con el hub competitivo de XDefiant, XDI, consultamos con los jugadores top para clasificar las armas metas para Ranked. Haz clic en las armas para ver los equipamientos favoritos de varias jugadores!",
        rankedToggle: "Para ver una meta mas pensado en partidos casuales, seleccionar 'Casual'.",
        casualTitle: 'XDefiant Meta Tierlist',
        casualDesc: "Ver la meta tierlist y encontrar las armas y accesorios mejores de XDefiant y equipamientos de los creadores y jugadores competitivos top. Haz clic en cualquier arma para ver equipamientos destacados.",
        casualToggle: "Para ver una meta mas pensado en modos Ranked, seleccionar 'Ranked'.",
        featuredLoadouts: 'Equipamientos Destacados',
        noFeaturedLoadouts: 'Todavía no hay equipamientos destacados.',
        xdlRecommended: 'Recomendaciones de XDLoadout',
        //Build Types
        Aggressive: 'Agresivo',
        'Main AR': 'Main AR',
        'Heavy': 'Pesado',
        'Flex': 'Flex',
        'Long-Range': 'Largo Alcance',
        'Movement': 'Movimiento',
        'Quickscope': 'Quickscope',
        'Fast': 'Rápido',
    },
    misc: {
        adblockText: 'Por favor pensar en excluir XDLoadout de tu AdBlocker. No vamos nunca a tener muros de pago y anuncios ayuda en apoyar al proyecto.',
        bottomDisclaimer: 'XDLoadout no tiene ninguna asociación con XDefiant ni Ubisoft',
        loginMain: 'Inicia sesión para guardar tus equipamientos y compartirlos con la comunidad!',
        loginWithDiscord: 'Inicia sesión con Discord',
        loginPrivacy: `Al iniciar sesión con Discord, das acuerdo a nuestra`,
        privacyPolicy: 'política de privacidad',
        profile: 'Perfil',
        bookmarks: 'Elementos Guardados',
        logout: 'Cerrar Sesión'
    },
    profiles: {
        title: 'Perfiles Destacados',
        failedGet: "No se podría encontrar los perfiles destacados en este momento.",
        seeMore: 'Ver Más',
        'Content Creators': 'Creadores de Contenido',
        'Comp Players': 'Jugadores Competitivos',
        'Edit Socials': 'Editar Redes Sociales',
        add: 'Añadir',
        displayName: 'Nombre de Perfil',
        pronouns: 'Pronombres',
        location: 'Ubicación',
        bio: 'Bio',
        optional: 'optativo',
        userPanelText: "Toda la información aquí será visible para todos. Haz clic en la papelera para quitar un campo.",
        urlCountdown: 'Puedes actualizar tu URL de perfil en',
        urlUpdateLimit: 'URLs de perfil solo se pueden cambiar cada 7 dias.',
        //LoadoutMenu
        copyLink: 'Copiar Enlace',
        saveImage: 'Guardar como Imagen',
    },
    playerStats: {
        leaderboardLink: "Para reclamar tu posición en las clasificaciones, asegurar que tienes un perfil de XDLoadout con cuenta Discord vinculada a tu cuenta Ubisoft.",
        how: "Como?",
        leaderboardsTitle: "Clasificaciones",
        rankTitle: 'Ranked',
        levelTitle: 'Nivel',
        killsTitle: 'Bajas',
        kdRatioTitle: 'Prop. B/M',
        winsTitle: 'Victorias',
        mvpsTitle: 'Veces MVP',
        //LinkDialog
        howToLinkTitle: 'Como vincular Discord a cuenta Ubisoft',
        step1: 'Inicia sesión en',
        step2A: 'Desplazar hacia abajo a',
        step2B: '"Cuentas Vinculadas"',
        step3A: 'Al Discord, haz clic en',
        step3B: '"Vincular"',
        step4: 'Autorizar con Discord (puede ser que tengas que iniciar sesión)',
        step5: 'Haz click en "Refresh" (puede tardar unos minutos para que todo sea actualizado)',
        //User Stats Panel
        kdr: 'Prop. B/M',
        wins: 'Victorias',
        losses: 'Derrotas',
        winRate: 'Por. Victorias',
        winRatio: 'Prop. V/D',
        mvpCount: 'Veces MVP',
        mvpRate: 'Por. MVP',
        kills: 'Bajas',
        assists: 'Asistencias',
        assistRate: 'Por. Assist.',
        killsPerGame: 'Bajas/Partido',
        assistsPerGame: 'Assist./Partido',
        score: 'Puntuación',
        scorePerMin: 'Punt./Min',
        scorePerGame: 'Punt./Partido',
        damage: 'Daño',
        damagePerKill: 'Daño/Baja',
        winStreak: 'Racha Victorias',
        longestLifetime: 'Vida Mas Largo',
        abilityKills: 'Bajas con Habil.',
        abilitiesUsed: 'Habil. Usados',
        ultrasUsed: 'Ultras Usados',
        matches: 'Partidos',
        mvp: 'MVP',
        abilities: 'Habilidades',
        progression: 'Progreso',
        favouriteFaction: 'Facción Favorito',
        "gamemode": "Modo",
        "Gamemode": "Modo",
        "gamemodes": "Modos",
        "Domination": "Dominación",
        "Team Deathmatch": "Duelo por Equipos",
        "Capture the Flag": "Captura la Bandera",
        "Zone Control": "Control de Zona",
        "Hot Shot": "Figura",
        "Escort": "Escolta",
        "Occupy": "Ocupación",
        weaponClass: "Tipo de Arma",
        weaponClasses: "Tipos de Arma",
        unlinkStats: 'Desvincular Estadísticas',
        lastUpdated: 'Última actualización',
        career: 'Carrera',
        statsMayDiffer: 'Nota que estadísticas pueden ser distinto a las enseñadas en el juego.',
        statsFromUbi: 'XDLoadout usa APIs de Ubisoft que a veces no rastrean correctamente.',
        moreHere: 'Más Aquí'
    }
}

export default translations