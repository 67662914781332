import * as React from "react";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";
import BasePage from "./BasePage";
import StatsTracking from "./profile/stats/StatsTracking";
import {Paper, Stack} from "@mui/material";

const StatTrackPage = ({}) => {
    const {t} = useTranslation(Namespaces.playerStats)

    return <BasePage title={t('statTrackTitle')}>
        <StatsTracking/>
    </BasePage>
}

// export const Panel = ({children}) => <Stack p='10px' m='10px 0' backgroundColor='rgb(0,0,0,0.25)' borderRadius='15px' border='1px solid #aaa' width='calc(100% - 22px)'>{children}</Stack>
export const Panel = ({children}) => <Paper elevation={2} sx={{width: '100%', borderRadius: '15px', p: '10px', m: '10px 0'}}><Stack m='10px 0' width='calc(100% - 20px)'>{children}</Stack></Paper>

export default StatTrackPage