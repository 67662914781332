import {Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, Stack, Typography} from "@mui/material";
import {ItemPaper} from "./WeaponBuilder";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoTooltip from "../components/InfoTooltip";
import CleanerIcon from "../assets/Wht-Cleaners-IncendiaryRounds.webp"
import PhantomIcon from "../assets/Wht-Phantoms-Fortified.webp"
import Image from 'react-bootstrap/Image';
import MultipliersSettings, {IncDecButton} from "./settings/MultiplierSettings";
import {useContext} from "react";
import {SettingsContext} from "../context/SettingsContext";
import SettingsIcon from "@mui/icons-material/Settings";
import {FeedbackContext, FeedbackType} from "../context/UserFeedback";
import {useIsMobileHook} from "../utils";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const CLEANER_RANGE_TOOLTIP = "Includes 10% damage range reduction"
const PHANTOM_HEALTH_TOOLTIP = "Uses 120 health for calculating STK and TTK"

export const CLEANER_DAMAGE = 8;
const CLEANER_RANGE_REDUCTION = 0.1;
export const PHANTOM_HEALTH = 120;

const GridItem = ({image, title, tooltip, checked, onCheck}) => {
    return (
        <Stack direction='row' alignItems='center' width='100%'>
            <Stack direction='row' justifyContent='end' alignItems='center' width='75%'>
                {image}
                <Typography variant='small' sx={{marginBottom: '3px'}}>
                    {title}
                </Typography>
                <InfoTooltip text={tooltip}/>
            </Stack>
            <Checkbox
                sx={{
                    padding: '5px',
                    color: 'white',
                    '&.Mui-checked': {
                        color: 'white',
                    },
                }}
                size='small'
                checked={checked}
                onChange={onCheck}
            />
        </Stack>
    )
};


function Accuracy() {
    const {t} = useTranslation(Namespaces.builder)
    const isMobile = useIsMobileHook()
    const {settings, setSettings} = useContext(SettingsContext)
    const {triggerFeedback} = useContext(FeedbackContext)

    const handleAccuracy = (newValue) => {
        if (newValue < 50) triggerFeedback("There's no way you're that bad.", FeedbackType.ERROR)
        setSettings({...settings, accuracy: Math.min(Math.max(50, newValue), 100)})
    }

    return <Stack direction='row' alignItems='end' m={isMobile ? '20px 0 0' : '0 0 0 25px'}>
        <Typography variant='small' sx={{marginBottom: '3px', minWidth: '100px'}}>
            {t('accuracy')}:
        </Typography>
        <Stack direction='row' alignItems='center' sx={{ml: '5px'}}>
            <IncDecButton onClick={() => handleAccuracy(settings.accuracy - 5)}>-</IncDecButton>
            <Typography variant='small'
                        sx={{p: '0 5px', userSelect: 'none'}}>{settings.accuracy + '%'}</Typography>
            <IncDecButton onClick={() => handleAccuracy(settings.accuracy + 5)}>+</IncDecButton>
        </Stack>
    </Stack>;
}


function PelletsMissed() {
    const {t} = useTranslation(Namespaces.builder)
    const isMobile = useIsMobileHook()
    const {settings, setSettings} = useContext(SettingsContext)
    const {triggerFeedback} = useContext(FeedbackContext)

    const handlePellets = (newValue) => {
        if (newValue > 11) triggerFeedback("There's no way you're that bad.", FeedbackType.ERROR)
        setSettings({...settings, pelletsMissed: Math.min(Math.max(0, newValue), 11)})
    }

    return <Stack direction='row' alignItems='center' m={isMobile ? '20px 0 0' : '0 0 0 25px'}>
        <Stack direction='row' alignItems='center' minWidth='100px'>
            <Typography variant='small' sx={{marginBottom: '3px'}}>
                {t('pelletsMissed')}:
            </Typography>
            <InfoTooltip text={t('pelletsMissedTooltip')}/>
        </Stack>
        <Stack direction='row' alignItems='center' sx={{ml: '5px'}}>
            <IncDecButton onClick={() => handlePellets(settings.pelletsMissed - 1)}>-</IncDecButton>
            <Typography variant='small'
                        sx={{p: '0 5px', userSelect: 'none'}}>{settings.pelletsMissed}</Typography>
            <IncDecButton onClick={() => handlePellets(settings.pelletsMissed + 1)}>+</IncDecButton>
        </Stack>
    </Stack>
}

const AdvancedSettings = () => {
    const {t} = useTranslation(Namespaces.builder)
    const isMobile = useIsMobileHook()

    const {settings, setSettings} = useContext(SettingsContext)

    const handleCleanerDamage = (event) => {
        setSettings({
            ...settings,
            cleanerDamage: event.target.checked ? CLEANER_DAMAGE : 0,
            rangePenalty: event.target.checked ? CLEANER_RANGE_REDUCTION : 0
        });
    };
    const handleCleanerRange = (event) => {
        setSettings({...settings, rangePenalty: event.target.checked ? CLEANER_RANGE_REDUCTION : 0});
    };
    const handlePhantomHealth = (event) => {
        setSettings({...settings, enemyHealth: event.target.checked ? PHANTOM_HEALTH : 100});
    };
    const handleAdsTtk = (event) => {
        setSettings({...settings, adsTtk: event.target.checked});
    };
    const handleStfTtk = (event) => {
        setSettings({...settings, stfTtk: event.target.checked});
    };
    const handleMultiplierValue = (newValue, name) => {
        const newMultipliers = {...settings.multipliers}
        newMultipliers[name] = Math.min(Math.max(0, newValue), 15)
        setSettings({...settings, multipliers: newMultipliers})
    }

    return (
        <Accordion elevation={0} disableGutters sx={{m: '0 5px', maxHeight: '56px', flex: 1, borderRadius: '5px'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                aria-controls="advanced-content"
                id="advanced-header"
            >
                <Stack direction='row' alignItems='center' spacing='8px'>
                    <SettingsIcon sx={{color: 'white'}}/>
                    <Typography variant='h6'>{t('advanced')}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{p: 0}}>
                <ItemPaper elevation={0} sx={{
                    p: '8px 16px 16px',
                    m: 0,
                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 8px 10px 0px rgba(0,0,0,0.12)'
                }}>
                    <Grid container sx={{width: '100%', p: '0 10px'}} alignItems='center'
                          justifyContent={isMobile ? 'center' : 'start'}>
                        <Stack direction={isMobile ? 'column' : 'row'} p='10px' borderRadius='3px'
                               alignSelf={isMobile ? 'center' : 'start'}
                               backgroundColor='rgba(100,100,100,0.25)'>
                            <MultipliersSettings settings={settings} handleMultiplierValue={handleMultiplierValue}/>
                            <Stack alignSelf='end'>
                                <Accuracy/>
                                <PelletsMissed/>
                            </Stack>
                        </Stack>
                        <Stack direction={isMobile ? 'column' : 'row'} width='100%' mt='15px'>
                            <Stack width={isMobile ? '100%' : '50%'}>
                                <GridItem title={`${t('cleanerDamage')}:`}
                                          tooltip={t('cleanerDamageTooltip')}
                                          checked={settings.cleanerDamage === CLEANER_DAMAGE}
                                          onCheck={handleCleanerDamage}
                                          image={
                                              <Image
                                                  style={{paddingRight: '10px'}}
                                                  height='25px'
                                                  src={CleanerIcon}
                                                  fluid
                                                  rounded
                                              />
                                          }
                                />
                                <GridItem title={`${t('cleanerRange')}:`}
                                          tooltip={t('cleanerRangeTooltip')}
                                          checked={settings.rangePenalty === CLEANER_RANGE_REDUCTION}
                                          onCheck={handleCleanerRange}
                                />
                                <GridItem title={`${t('phantomHealth')}:`}
                                          tooltip={t('phantomHealthTooltip')}
                                          checked={settings.enemyHealth === PHANTOM_HEALTH}
                                          onCheck={handlePhantomHealth}
                                          image={
                                              <Image
                                                  style={{paddingRight: '10px', paddingBottom: '5px'}}
                                                  height='23px'
                                                  src={PhantomIcon}
                                                  fluid
                                                  rounded
                                              />}
                                />
                            </Stack>
                            <Stack width={isMobile ? '100%' : '50%'}>
                                <GridItem title={`${t('includeAds')}:`}
                                          tooltip={t('includeAdsTooltip')}
                                          checked={settings.adsTtk}
                                          onCheck={handleAdsTtk}
                                />
                                <GridItem title={`${t('includeStf')}:`}
                                          tooltip={t('includeStfTooltip')}
                                          checked={settings.stfTtk}
                                          onCheck={handleStfTtk}
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                </ItemPaper>
            </AccordionDetails>
        </Accordion>
    )
}

export default AdvancedSettings;
