import {Button, Stack, styled, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {buildWeapon, ItemPaper} from "./WeaponBuilder";
import AdvancedSettings, {CLEANER_DAMAGE, PHANTOM_HEALTH} from "./AdvancedSettings";
import {useIsMobileHook} from "../utils";
import {SettingsContext} from "../context/SettingsContext";
import Compare from "./Compare";
import NewGraph from "./NewGraph";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";
import {ZoomIn, ZoomOut} from "@mui/icons-material";


export const LightToggleButton = styled(ToggleButton)({
    '&.MuiToggleButton-root': {
        backgroundColor: 'rgba(0,0,0,0.15)',
        color: '#aaa',
    },
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: 'rgba(60,60,60,0.25)',
        color: '#fff',
    },
})

function TTKGraph({currentWeapon, savedLoadouts}) {
    const {t} = useTranslation(Namespaces.weaponStats)
    const {settings} = useContext(SettingsContext)

    const isMobile = useIsMobileHook()
    const [graphType, setGraphType] = useState('ttk')
    const [graphExpanded, setGraphExpanded] = useState(false)
    const [comparisonLoadouts, setComparisonLoadoutsState] = useState([])

    const setComparisonLoadouts = (loadouts) =>
        setComparisonLoadoutsState(loadouts.map((l) => {
            return {...l, calculatedWeapon: buildWeapon(l.weapon, l.attachments, settings).customWeapon, name: l.name}
        }))

    useEffect(() => {
        setComparisonLoadouts(comparisonLoadouts)
    }, [settings])

    const handleGraphType = (event, newGraphType) => {
        if (newGraphType != null) setGraphType(newGraphType)
    };

    return (
        <Stack>
            <Stack position='relative' minHeight='63px'>
                <Stack direction='row' position='absolute' zIndex='100'>
                    <AdvancedSettings/>
                    {isMobile ? null : <Compare savedLoadouts={savedLoadouts} comparisonLoadouts={comparisonLoadouts}
                                                setComparisonLoadouts={setComparisonLoadouts}/>}
                </Stack>
            </Stack>
            <ItemPaper sx={{maxHeight: '60vh'}}>
                <Stack sx={{height: '100%'}} alignItems='center' marginTop='5px' position='relative'>
                    <ToggleButtonGroup
                        value={graphType}
                        exclusive
                        onChange={handleGraphType}
                        aria-label="graphType-select"
                    >
                        <LightToggleButton value="ttk" aria-label="left aligned">
                            {t('ttk')}
                        </LightToggleButton>
                        <LightToggleButton value="stk" aria-label="centered">
                            {t('stk')}
                        </LightToggleButton>
                        <LightToggleButton value="damage" aria-label="right aligned">
                            {t('damageRanges')}
                        </LightToggleButton>
                    </ToggleButtonGroup>
                    {isMobile ? null : <Button variant="contained" onClick={() => setGraphExpanded(!graphExpanded)} size='small' sx={{position: 'absolute', right: '5px', top: 0, textTransform: 'none'}}>
                        {graphExpanded ? <ZoomOut/> : <ZoomIn/>}
                    </Button>}
                    {!isMobile  ? <Typography mt='10px' variant='smallHead'>
                        &nbsp;
                        {(settings.multipliers?.head || settings.multipliers?.chest || settings.cleanerDamage === CLEANER_DAMAGE || settings.enemyHealth === PHANTOM_HEALTH || settings.pelletsMissed > 0) ? <b style={{marginRight: '5px'}}>Displaying:</b> : null}
                        {settings.cleanerDamage === CLEANER_DAMAGE ? <a style={{margin: '0 5px'}}>Cleaner Damage</a> : null}
                        {settings.enemyHealth === PHANTOM_HEALTH ? <a style={{margin: '0 5px'}}>Phantom Health</a> : null}
                        {settings.multipliers?.head > 0 ? <a style={{margin: '0 5px'}}>Headshots: {settings.multipliers.head}</a> : null}
                        {settings.multipliers?.chest > 0 ? <a style={{margin: '0 5px'}}>Chestshots: {settings.multipliers.chest}</a> : null}
                        {settings.pelletsMissed > 0 ? <a style={{margin: '0 5px'}}>{settings.pelletsMissed} Pellets Missed</a> : null}
                    </Typography> : null}
                    <NewGraph expanded={graphExpanded} graphType={graphType} weapon={currentWeapon} comparisonLoadouts={comparisonLoadouts}/>
                </Stack>
            </ItemPaper>
        </Stack>
    )
}

export default TTKGraph
