import {IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useContext, useState} from "react";
import {FeedbackContext, FeedbackType} from "../../../context/UserFeedback";
import {updateLoadouts} from "../../../api/user/UpdateUser";
import {
    Bookmark,
    BookmarkBorder,
    Delete,
    Favorite,
    FavoriteBorder,
    Image,
    Link,
    MoreVert,
    Star,
    StarBorder
} from "@mui/icons-material";
import {UserContext} from "../../../context/UserContext";
import {bookmarkLoadout, likeLoadout, unbookmarkLoadout, unlikeLoadout} from "../../../api/user/LoadoutActions";
import LoadoutCard from "../../../builder/share/LoadoutCard";
import {useComponentToImage, useIsMobileHook} from "../../../utils";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../../config/localisation/Localisation";


const LikesPanel = ({loadout, isOwnProfile}) => {
    const {user, setUser} = useContext(UserContext)
    const {triggerFeedback} = useContext(FeedbackContext)

    const [likesOverride, setLikesOverride] = useState()

    const [liking, setLiking] = useState(false)

    const likes = likesOverride?.count ?? loadout.likes?.count ?? 0
    const userLiked = user != null && user.likedLoadouts.includes(loadout.id)

    const handleLike = () => {
        if (user == null) return triggerFeedback(`Only logged in users can like loadouts. Stay tuned to @XDLoadout on twitter for the public release or to get your access code!`, FeedbackType.INFO)
        if (isOwnProfile) return triggerFeedback(`Cringe, you can't like your own loadout...`, FeedbackType.INFO)
        if (liking) return
        setLiking(true)
        return (userLiked ? unlikeLoadout(loadout.id) : likeLoadout(loadout.id))
            .then(response => {
                setLikesOverride(response.likes)
                setUser({
                    ...user,
                    likedLoadouts: userLiked ? user.likedLoadouts.filter(id => id !== loadout.id) : [...user.likedLoadouts, loadout.id]
                })
            })
            .catch(e => {
                triggerFeedback(`Failed to ${userLiked ? 'un' : ''}like loadout. Please report this to @XDLoadout on twitter. ):`, FeedbackType.ERROR)
            }).finally(() => setLiking(false))
    }

    return <Stack direction='row' alignItems='center'
                  sx={{p: '3px 8px', borderRadius: '3px', backgroundColor: '#0000004D'}}>
        <Typography sx={{color: 'white', pb: '0px'}}>{likes}</Typography>
        <IconButton sx={{p: 0, ml: '8px'}} onClick={() => handleLike()}>
            {user == null || isOwnProfile || userLiked ?
                <Favorite sx={{color: userLiked ? 'red' : 'white', fontSize: '1rem'}}/>
                : <FavoriteBorder sx={{color: 'white', fontSize: '1rem'}}/>}
        </IconButton>
    </Stack>
}

const LoadoutOptions = ({loadout, user}) => {
    const {t} = useTranslation(Namespaces.profiles)
    const {triggerFeedback} = useContext(FeedbackContext)

    const {ref, convert} = useComponentToImage(loadout)

    const [anchorEl, setAnchorEl] = React.useState(null)
    const userMenuOpen = Boolean(anchorEl)

    const handleUserClick = e => {
        setAnchorEl(e.currentTarget);
        e.stopPropagation()
    }
    const handleUserMenuClose = e => {
        setAnchorEl(null);
        e.stopPropagation()
    }

    const getLink = () => {
        navigator.clipboard.writeText(`https://xdloadout.pro/loadouts/${loadout.id}`)
        triggerFeedback('Link copied to clipboard!', FeedbackType.SUCCESS)
    }

    return <UserIconButton onClick={handleUserClick} sx={{overflow: 'hidden'}}>
        <LoadoutCard captureRef={ref} loadout={loadout} user={loadout.user}/>
        <MoreVert sx={{color: 'white'}}/>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={userMenuOpen}
            onClose={handleUserMenuClose}
            onClick={handleUserMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    bgcolor: 'background.default',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.default',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            <MenuItem onClick={getLink}>
                <ListItemIcon>
                    <Link fontSize="small" sx={{color: '#CCC'}}/>
                </ListItemIcon>
                {t('copyLink')}
            </MenuItem>
            <MenuItem onClick={convert}>
                <ListItemIcon>
                    <Image fontSize="small" sx={{color: '#CCC'}}/>
                </ListItemIcon>
                {t('saveImage')}
            </MenuItem>
        </Menu>
    </UserIconButton>
}

const LoadoutMenu = ({loadout, hideButtons, isOwnProfile}) => {
    const {user, setUser} = useContext(UserContext)
    const isMobile = useIsMobileHook()
    let hidden = hideButtons ?? []
    if (!isOwnProfile) hidden = [...hidden, 'favourite', 'delete']
    return (
        <Stack>
            <Stack direction='row' sx={{position: 'absolute', right: '10px', top: '10px'}}>
                {hidden.includes('favourite') ? null :
                    <FavouriteButton user={user} setUser={setUser} loadout={loadout}/>}
                {hidden.includes('delete') ? null : <DeleteButton user={user} setUser={setUser} loadout={loadout}/>}
                {isOwnProfile ? null : <BookmarkButton user={user} setUser={setUser} loadout={loadout}/>}
                <LoadoutOptions user={user} loadout={loadout}/>
            </Stack>
            <Stack direction='row' sx={{position: 'absolute', left: '10px', top: isMobile ? '8px':'10px'}}>
                <LikesPanel loadout={loadout} isOwnProfile={isOwnProfile}/>
            </Stack>
        </Stack>
    )
}

const UserIconButton = (props) => {
    const isMobile = useIsMobileHook()
    return <IconButton {...props} sx={{
        padding: isMobile ? 0 : '3px',
        '&:hover': {
            filter: 'drop-shadow(0px 0px 6px rgb(200 200 200))',
            background: 'none'
        },
        overflow: 'hidden'
    }}/>
}

const DeleteButton = ({user, loadout, setUser}) => {
    const {triggerFeedback} = useContext(FeedbackContext)
    return user == null ? null :
        <UserIconButton onClick={() => {
            updateLoadouts([{...loadout, public: false}])
                .then(u => {
                    setUser({...u, icon: user.icon});
                    triggerFeedback('Loadout removed from profile!', FeedbackType.SUCCESS)
                })
                .catch(() => triggerFeedback('Failed to remove loadout from profile. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR))
        }}>
            <Delete sx={{color: 'white'}}/>
        </UserIconButton>
}

const FavouriteButton = ({user, loadout, setUser}) => {
    const {triggerFeedback} = useContext(FeedbackContext)
    return user == null ? null :
        <UserIconButton onClick={() => {
            if (!loadout.favourite && user.loadouts.filter(l => l.favourite) > 4) {
                triggerFeedback('You can only have 5 favourite loadouts.', FeedbackType.ERROR)
                return
            }
            loadout.favourite = !loadout.favourite
            updateLoadouts([loadout])
                .then(u => {
                    setUser({...u, icon: user.icon});
                    triggerFeedback(`Loadout ${loadout.favourite ? '' : 'un'}favourited!`, FeedbackType.SUCCESS)
                })
                .catch(() => triggerFeedback(`Failed to ${loadout.favourite ? 'add' : 'remove'} loadout to favourites. Please report this to @XDLoadout on twitter. ):`, FeedbackType.ERROR))
        }}>
            {loadout.favourite ? <Star sx={{color: 'gold'}}/> : <StarBorder sx={{color: 'white'}}/>}
        </UserIconButton>
}

const BookmarkButton = ({user, loadout, setUser}) => {
    const {triggerFeedback} = useContext(FeedbackContext)
    if (!user) return null
    const bookmarked = user.bookmarkedLoadouts.includes(loadout.id)
    return (
        <UserIconButton onClick={() => {
            (bookmarked ?
                    unbookmarkLoadout(loadout.id)
                        .then(r => setUser({
                            ...user,
                            bookmarkedLoadouts: user.bookmarkedLoadouts.filter(bl => bl !== loadout.id)
                        }))
                    : bookmarkLoadout(loadout.id)
                        .then(r => setUser({...user, bookmarkedLoadouts: [...user.bookmarkedLoadouts, loadout.id]}))

            )
                .then(triggerFeedback(`Loadout ${bookmarked ? 'un' : ''}bookmarked!`, FeedbackType.SUCCESS))
                .catch(() => triggerFeedback(`Failed to ${bookmarked ? 'un' : ''}bookmark loadout. Please report this to @XDLoadout on twitter. ):`, FeedbackType.ERROR))
        }}>
            {bookmarked ? <Bookmark sx={{color: '#0072ff'}}/> : <BookmarkBorder sx={{color: 'white'}}/>}
        </UserIconButton>
    )
}

export default LoadoutMenu