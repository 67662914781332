import React, {createContext, useState} from "react";

export const DEFAULTS = {
    cleanerDamage: false,
    rangePenalty: 0,
    enemyHealth: 100,
    accuracy: 100,
    pelletsMissed: 0,
    multipliers: {head: 0, chest: 0, leg: 0}
}

export const SettingsContext = createContext(null)

export const SettingsContextProvider = ({children}) => {
    const [settings, setSettings] = useState(DEFAULTS)
    return <SettingsContext.Provider value={{settings, setSettings}}>
        {children}
    </SettingsContext.Provider>
}
