import {useMediaQuery} from "@mui/material";
import {theme} from "./index";
import {useLocation} from "react-router-dom";
import {useCallback, useEffect, useMemo, useRef} from "react";
import {toPng} from "html-to-image";
import {Profanity} from '@2toad/profanity';
import defaultNonos from '@2toad/profanity/dist/data/profane-words'

export const useIsMobileHook = () => !useMediaQuery(theme.breakpoints.up('md'))

export const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);

    return ref;
};

export const useComponentToImage = (loadout, options = {height: 600, width: 1125}) => {
    const ref = useRef(null)
    const convert = useCallback(() => {
        if (ref.current === null) {
            return
        }

        toPng(ref.current, options)
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = `${loadout?.weapon?.name}-xdloadout.png`
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {
                console.error(err)
            })
    }, [ref, loadout])
    return {ref, convert}
}

export const useQuery = () => {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const randomInt = (max) => Math.floor(Math.random() * max)

export const inputPlaceholder = (selected, placeholder) => {
    if (selected == null || selected.length === 0) {
        return placeholder;
    }
    return selected;
}

export function weaponImageName(weapon) {
    return weapon?.name?.toLowerCase().replaceAll(' ', '-').replaceAll('.', '');
}

export const escapeHTML = (html) => {
    return html
        .replaceAll('&', "&amp;")
        .replaceAll('<', "&lt;")
        .replaceAll('>', "&gt;")
        .replaceAll('"', "&quot;")
        .replaceAll("'", "&#039;");
}

const nonos = `YWJibw]YWJv]YXNzYmFuZGl0]YXNzLWZ1Y2tlcg]YXNzZnVja2Vy]YXNzZnVra2E]YmVhbmVy]YmVhbmVycw]YmVhc3RpYWw]YmVhc3RpYWxpdHk]YmVzdGlhbGl0eQ]Yml0Y2hib3k]Ym9pb2xh
cw]YnVjZXRh]YzBja3N1Y2tlcg]Y2FycGV0IG11bmNoZXI]Y2hpbms]Y2lwYQ]Y29jay1zdWNrZXI]Y29ja211bmNo]Y29ja211bmNoZXI]Y29ja3N1Y2s]Y29ja3N1Y2tlZA]Y29ja3N1Y2tlcg
]Y29ja3N1Y2tpbmc]Y29ja3N1Y2tz]Y29ja3N1a2E]Y29ja3N1a2th]Y29rbXVuY2hlcg]Y29rc3Vja2E]Y29vbg]Y29vbnM]Y3VudGxpY2s]Y3VudGxpY2tlcg]Y3VudGxpY2tpbmc]Y3lhbGlz
]Y3liZXJmdWM]Y3liZXJmdWNr]Y3liZXJmdWNrZWQ]Y3liZXJmdWNrZXI]Y3liZXJmdWNrZXJz]Y3liZXJmdWNraW5n]ZGlyc2E]ZWNjaGk]ZG9nLWZ1Y2tlcg]ZG9ua2V5cmliYmVy]ZmFn]ZmF
nYmFn]ZmFnZw]ZmFnZ2luZw]ZmFnZ2l0]ZmFnZ2l0dA]ZmFnZ290]ZmFnZ3M]ZmFnb3Q]ZmFnb3Rz]ZmFncw]ZmFndGFyZA]ZmFubnlmdWNrZXI]ZmF0YXNz]ZmlzdGZ1Y2s]ZmlzdGZ1Y2tlZA]
ZmlzdGZ1Y2tlcg]ZmlzdGZ1Y2tlcnM]ZmlzdGZ1Y2tpbmc]ZmlzdGZ1Y2tpbmdz]ZmlzdGZ1Y2tz]ZnVkZ2UgcGFja2Vy]ZnVkZ2VwYWNrZXI]Z2F5bG9yZA]Z29vaw]Z29saXdvZw]Z29sbGl3b
2c]Z2lwc3k]Z3lwc3k]aG9hcg]aG9hcmU]aG9lcg]aG9yZQ]amFw]amFpbGJhaXQ]amlnYWJvbw]amlnZ2Fib28]amlnZ2VyYm9v]a2lrZQ]a25vYmpvY2t5]a25vYmpva2V5]bjFnZ2E]bjFnZ2
Vy]bmF6aQ]bmVvbmF6aQ]bmVnZXI]bmlnIG5vZw]bmlnZzNy]bmlnZzRo]bmlnZ2E]bmlnZ2Fo]bmlnZ2Fz]bmlnZ2F6]bmlnZ2Vy]bmlnZ2VycyA]bmlnZ2Vycw]bmlnbGV0]cDRraQ]cDRrMQ]
cGFraQ]cGFrMQ]cGVuaXNmdWNrZXI]cGlzc2ZsYXBz]cmFwZQ]cmF5cA]cmF5cGU]cmV0YXJk]c2hlbWFsZQ]c2thbms]c21lZ21h]dGFyZA]dG93ZWxoZWFk]dHJhbm55]d2hvYXI]d2hvcmU`

function setAllowedWords() {
    const filter = new Profanity({grawlix: '!!!!', wholeWord: true});
    filter.removeWords(defaultNonos)
    filter.addWords(nonos.split(']').map(atob))
    filter.addWords(nonos.split(']').map(atob).map(nono => `${nono}s`))
    return filter
}

export const profanityFilter = setAllowedWords();

export const lowerOffset = {
    popper: {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, -7],
                },
            },
        ],
    },
}

export const numberFormatter = new Intl.NumberFormat('en-US')
