import {StatsGraphPanel} from "./StatsGraph";
import UserStats, {Stats} from "./UserStats";
import {Skeleton, Stack, Typography} from "@mui/material";
import * as React from "react";
import {createContext, useContext, useState} from "react";
import {useIsMobileHook} from "../../../utils";
import {UserContext} from "../../../context/UserContext";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../../config/localisation/Localisation";
import DesktopHorizontalBannerAd from "../../../components/ads/DesktopHorizontalBannerAd";
import {SessionPanel} from "./SessionPanel";
import {useNavigate, useParams} from "react-router-dom";

const numberOfGamesToTargetKd = (kills, deaths, kpg, targetKd, avgKd) => ((targetKd * deaths) - kills) / (kpg * (avgKd - targetKd))
const avgKdToTargetKd = (kills, deaths, kpg, targetKd, games) => (kpg * games * targetKd) / (kills + (kpg * games) - (targetKd * deaths))

function Target({data}) {
    const targetKD = Math.round(data.latest.kdr * 100) / 100 + 0.05
    const avgKd = 2;
    const kpg = data.latest.kills.killsPerGame;
    const kills = data.latest.kills.total;
    const deaths = kills / data.latest.kdr;
    const games = 50;

    return <Stack>
        <Typography variant='mid'>Improve K/D to {targetKD}: Average
            a {avgKdToTargetKd(kills, deaths, kpg, targetKD, games)} in your next {games} games</Typography>
        <Typography variant='mid'>Improve K/D to {targetKD}: Average a {avgKd} K/D
            for {numberOfGamesToTargetKd(kills, deaths, kpg, targetKD, avgKd)} games</Typography>
    </Stack>
}

export const StatsSpans = {
    CAREER: {name: 'career', getter: (stats) => stats.latest},
    // TODAY: {
    //     name: 'Today',
    //     getter: (stats) => stats.today,
    // },
    S0: {
        name: 'preseason',
        getter: (stats) => stats.seasonal['0']?.abs,
    },
    S1: {
        name: 'season',
        nameSuffix: ' 1',
        getter: (stats) => stats.seasonal['1']?.dif,
        startDate: 1719878400000,
    },
    // Session: {
    //     name: 'session',
    //     getter: session => session.overall,
    //     session: true
    // },
}


export const SimpleButton = ({onClick, selected, text, size = 'normal', suffix = ''}) => {
    const Sizes = {
        small: {p: '3px', m: '3px', variant: 'small'},
        normal: {p: '5px', m: '5px'},
    }
    const {t} = useTranslation(Namespaces.playerStats)
    return <Typography sx={{...Sizes[size], cursor: 'pointer', userSelect: 'none'}}
                       backgroundColor={selected ? 'rgba(50,50,50,0.2)' : 'rgba(0,0,0,0.2)'}
                       borderRadius='5px'
                       boxShadow={selected ? '0 0 3px #ccc' : null}
                       variant={Sizes[size].variant}
                       color='white'
                       onClick={onClick}>
        {t(text) + suffix}
    </Typography>;
}


export function StatsButtons({setStat, stat, filters = []}) {
    return <Stack direction='row' width='100%' flexWrap='wrap' alignItems='center' justifyContent='center'>
        {Stats.filter(s => filters.find(f => (s.filters ?? []).includes(f)) == null).map(s => <SimpleButton
            onClick={() => setStat(s)}
            selected={stat === s} text={s.label}
            size='small' key={s.label + '-button'}/>)}
    </Stack>;
}

const StatsTracking = () => {
    const isMobile = useIsMobileHook()
    const {user, userLoading} = useContext(UserContext)
    const {t} = useTranslation(Namespaces.playerStats)
    const {page} = useParams()
    const navigate = useNavigate()

    const [currentGraphStat, setCurrentGraphStat] = useState(Stats[0])
    const [statsSpan, setStatsSpan] = useState(StatsSpans.CAREER)

    const statsData = user?.profile?.stats

    return userLoading ? <StatsSkeleton/> :
        statsData != null ? <Stack alignItems='center' width='100%'>
            <StatTrackingContextProvider>
                <Stack direction='row'>
                    <SimpleButton text={t('sessionTracker')} selected={page === 'session'}
                                  onClick={() => navigate('/stats/session')}/>
                    <SimpleButton text={t('careerTracker')} selected={page === 'career'}
                                  onClick={() => navigate('/stats/career')}/>
                </Stack>
                {page === 'session' ? <SessionPanel/> : page === 'career' ?
                <Stack>
                    <UserStats expanded isOwnProfile={true} stats={statsData} statsSpan={statsSpan}
                               setStatsSpan={setStatsSpan}/>
                    {/*<Target data={statsData}/>*/}
                    <DesktopHorizontalBannerAd adSlot='stat-tracking'/>
                    <StatsGraphPanel stats={statsData} currentStat={currentGraphStat} statsSpan={statsSpan}/>
                    <StatsButtons setStat={setCurrentGraphStat} stat={currentGraphStat}/>
                </Stack> : null}
            </StatTrackingContextProvider>
        </Stack> : null

}

export const StatsTrackingContext = createContext(null)

const StatTrackingContextProvider = ({children}) => {
    const {user} = useContext(UserContext)
    const [stats, setStats] = useState(user.profile.stats)
    return <StatsTrackingContext.Provider value={{stats, setStats}}>
        {children}
    </StatsTrackingContext.Provider>
}

const StatsSkeleton = () => <Stack width='100%'>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
</Stack>

export default StatsTracking