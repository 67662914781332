import {Stack} from "@mui/material";
import {TitleText} from "../components/Text";
import * as React from "react";
import MobileAd, {MobileSlots} from "../components/ads/MobileAd";
import {useIsMobileHook} from "../utils";

const BasePage = ({title, children}) => {
    const isMobile = useIsMobileHook()
    return <Stack width='100%' alignItems='center' pt={isMobile ? '10px' : '5px'}>
        <MobileAd slot={MobileSlots.HORIZONTAL_1} nitroSlot='base-1'/>
        <Stack sx={{mt: {xs: 0, md: '10px'}, width: {xs: '97%', md: 'calc(min(max(80%, 960px), 100%))'}}}>
            <TitleText>
                {title}
            </TitleText>
            <Stack alignItems='center' width='100%'>
                {children}
            </Stack>
        </Stack>
    </Stack>
}

export default BasePage