import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createTheme, GlobalStyles, ThemeProvider} from "@mui/material";
import Routes from "./Routes";
import {render} from "react-dom";
import RootContextProvider from "./context/RootContextProvider";

export const theme = createTheme({
        typography: {
            fontFamily: 'Helvetica Neue, Arial',
            h7: {fontSize: '0.85rem'},
            largeHead: {fontSize: '2rem', fontWeight: 'bold', fontStyle: 'oblique', color: 'white'},
            midHead: {fontSize: '1.25rem', fontWeight: 'bold', color: 'white'},
            mid: {fontSize: '1.25rem', color: 'white'},
            midSmallHead: {fontSize: '0.9rem', fontWeight: 'bold', color: 'white'},
            contenthead: {fontSize: '0.9rem', fontWeight: 'bold', fontStyle: 'oblique', color: 'white'},
            content: {fontSize: '0.9rem', color: 'white'},
            smallHead: {fontSize: '0.85rem'},
            small: {fontSize: '0.8rem'}
        },
        palette: {
            type: 'light',
            primary: {
                main: '#1e3770',
            },
            secondary: {
                main: '#2a729a',
            },
            background: {
                default: '#323236',
                paper: '#1F1F21',
                paperLight: '#414144'
            },
            text: {
                primary: 'rgb(255,255,255)',
                secondary: 'rgba(255,255,255,0.75)',
            },
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        '&.MuiDialog-paper': {
                            overflow: 'hidden'
                        }
                    }
                }
            },
            MuiCollapse: {
                styleOverrides: {
                    root: {
                        position: 'relative',
                        top: '-10px'
                    }

                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        ':before': {
                            display: 'none'
                        }
                    }
                }
            },
            MuiMobileStepper: {
                styleOverrides: {
                    dot: {
                        backgroundColor: '#555'
                    },
                    dotActive: {
                        backgroundColor: 'white'
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgb(0,0,0,0.2)',
                        '&:hover': {
                            backgroundColor: 'rgba(255,255,255,0.1)'
                        },
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(255,255,255,0.1)'
                        }
                    },
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: '#eee'
                        }
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.1)'
                        },
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            '&:hover': {
                                backgroundColor: 'rgba(255,255,255,0.1)'
                            },
                        }
                    }
                }
            }
        },
        styleOverrides:
            {
                root: ({ownerState}) => ({
                    '&.MuiButton-contained.Mui-disabled': {
                        backgroundColor: theme.palette[ownerState.color].main,
                    },
                    '&.MuiListItemButton-root.Mui-selected': {
                        backgroundColor: 'rgba(255,255,255,0.1)'
                    },
                    '&.MuiDialog-paper': {overflow: 'hidden'}
                }),
            }
    })
;

render(
    <div>
        <ThemeProvider theme={theme}>
            <RootContextProvider>
                <Routes/>
            </RootContextProvider>
        </ThemeProvider>
        <GlobalStyles
            styles={{
                '*::-webkit-scrollbar': {
                    backgroundColor: 'rgba(255,255,255,0)',
                    width: '8px'
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(255,255,255,0)',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '16px',
                    border: '4px solid background.paper'
                },
                '*::-webkit-scrollbar-button': {
                    display: 'none'
                },
                '.img': {
                    webkitUserSelect: 'none',
                    userSelect: 'none'
                }
            }}
        />
    </div>
    , document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
